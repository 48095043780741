export const fontFamilies = [
  { value: 0, name: "Arial", label: "Arial, sans-serif" },
  { value: 1, name: "Verdana", label: "Verdana, sans-serif" },
  { value: 2, name: "Tahoma", label: "Tahoma, sans-serif" },
  { value: 3, name: "Trebuchet MS", label: "Trebuchet MS, sans-serif" },
  { value: 4, name: "Georgia", label: "Georgia, serif" },
  { value: 5, name: "Times New Roman", label: "Times New Roman, serif" },
  { value: 6, name: "Courier New", label: "Courier New, monospace" },
  { value: 7, name: "Comic Sans MS", label: "Comic Sans MS, cursive" },
];

export const fontWeights = [
  { name: "Light", value: "300", id: 1 },
  { name: "Normal", value: "400", id: 0 },
  { name: "Semi Bold", value: "600", id: 2 },
  { name: "Bold", value: "700", id: 3 },
  { name: "Extra Bold", value: "800", id: 4 },
];

export const fontSizes = [
  { name: "Small", label: "Small", value: 14, id: 1 },
  { name: "Medium", label: "Medium", value: 18, id: 0 },
  { name: "Large", label: "Large", value: 20, id: 2 },
  { name: "Extra Large", label: "Extra Large", value: 24, id: 3 },
  { name: "Huge", label: "Huge", value: 32, id: 4 },
];

export const streamArts = [
  {
    value: "image-1.png",
    name: "Ely Congrats",
    img_src:
      "https://cdn.eplayment.com/fm/06e80d248a14b72b9858104285a595cd/image-1.png",
  },
  {
    value: "image-2.png",
    name: "Hey",
    img_src:
      "https://cdn.eplayment.com/fm/06e80d248a14b72b9858104285a595cd/image-2.png",
  },
  {
    value: "image-3.png",
    name: "Ely Sad",
    img_src:
      "https://cdn.eplayment.com/fm/06e80d248a14b72b9858104285a595cd/image-3.png",
  },
  {
    value: "image-4.png",
    name: "Hello",
    img_src:
      "https://cdn.eplayment.com/fm/06e80d248a14b72b9858104285a595cd/image-4.png",
  },
];

export const audios = [
  {
    value: 1,
    name: "default",
    src: "/audios/default.mp3",
  },
];

export const layouts = ["flex-col", "absolute-center", "flex"];
