import ContentStreamCode from "pages-content/stream/stream-code";

const StreamCodePage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <ContentStreamCode />
    </div>
  );
};

export default StreamCodePage;
