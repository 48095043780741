import { Img } from "component/elements";
import { fontFamilies, fontSizes, fontWeights } from "./utils";

interface Props {
  alert_settings: {
    image_size: number;
    alert_duration: number;
    alert_heading: string;
    layout: number;
    font_family: number;
    font_weight: number;
    font_size: number;
    heading_text_color: string;
    message_text_color: string;
    audio_volume: number;
    image: string;
  };
  alert_heading: string;
  alert_message: string;
}

const Sticker: React.FC<Props> = ({
  alert_settings,
  alert_heading,
  alert_message,
}) => {
  return (
    <div
      style={{
        fontSize:
          fontSizes.find((x) => x.id === alert_settings.font_size)?.value +
          "px",
        fontWeight: fontWeights.find((x) => x.id === alert_settings.font_weight)
          ?.value,
        fontFamily: fontFamilies.find(
          (x) => x.value === alert_settings.font_family
        )?.label,
      }}
      className={[
        "z-10 whitespace-nowrap",
        alert_settings?.layout === 0
          ? " flex items-center justify-center flex-col"
          : "",
        alert_settings?.layout === 1
          ? "w-auto  flex items-center justify-center flex-col"
          : "",
        alert_settings?.layout === 2 ? "w-auto flex items-center" : "",
      ].join(" ")}
    >
      <Img
        style={{
          minWitdh: `${154 + alert_settings?.image_size}px`,
          minHeight: `${154 + alert_settings?.image_size}px`,
        }}
        draggable={false}
        src={alert_settings.image}
        alt={"art_stream_mode"}
        className="object-contain"
      />
      <div
        className={[
          "flex flex-col p-1 text-stroke",
          alert_settings?.layout === 0 ? "items-center" : "",
          alert_settings?.layout === 1
            ? "items-center  absolute top-1/2 -translate-y-1/2 text-center"
            : "",
          alert_settings?.layout === 2 ? " items-start text-right" : "",
        ].join(" ")}
      >
        <div
          style={{
            color: alert_settings?.heading_text_color ?? "white",
          }}
        >
          {alert_heading !== "" ? alert_heading : "{Name} donated {amount}"}
        </div>

        <div
          style={{
            color: alert_settings?.message_text_color ?? "white",
          }}
        >
          {alert_message}
        </div>
      </div>
    </div>
  );
};

export default Sticker;
