import axios from "axios";
import NotFoundPage from "pages/errors/not-found";
import StreamCodePage from "pages/stream/stream-code";
import { BrowserRouter, Routes, Route } from "react-router-dom";

axios.defaults.headers.get.Accept = "application/json";
axios.defaults.headers.post.Accept = "application/json";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/stream/:code" element={<StreamCodePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
