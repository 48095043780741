import { useState } from "react";

interface Props {
  src: string;
  alt?: string;
  className?: string;
  draggable?: boolean;
  showLoading?: boolean;
  altImg?: string;
  width?: string;
  height?: string;
  style?: any;
}

const Img: React.FC<Props> = ({
  src,
  alt = "img",
  className,
  draggable,
  showLoading,
  altImg = "",
  width,
  height,
  style,
}) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const onError = () => {
    setIsError(true);
  };
  const handleLoad = () => {
    setIsLoading(false);
  };
  return (
    <>
      <img
        onLoad={handleLoad}
        draggable={draggable}
        onError={onError}
        alt={alt}
        width={width}
        height={height}
        style={style}
        src={
          isError || !src
            ? altImg || "/images/eplayment-logo-placeholder.svg"
            : src
        }
        className={className}
      />
      {showLoading && isLoading && (
        <div className="flex h-full w-full bg-background-secondary bg-opacity-50 top-0 left-0 absolute animate-loading">
          <div className="m-auto text-white">
            <i className="fa fa-spin fa-spinner" />
          </div>
        </div>
      )}
    </>
  );
};

export default Img;
