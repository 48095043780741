import { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { useParams } from "react-router-dom";
import { motion, useAnimationControls } from "framer-motion";
import Sticker from "./Sticker";
// import axios from "axios";
import { audios } from "./utils";
import useLocalStorage from "hooks/useLocalStorage";
// import useLocalStorage from "hooks/useLocalStorage";

const ContentStreamCode = () => {
  const { code } = useParams();

  const alert_heading = "";
  const alert_message = "<No Message>";

  const [alertSettings, setAlertSettings] = useLocalStorage("alert_settings", {
    image_size: 50,
    alert_duration: 5,
    alert_heading: "",
    layout: 0,
    font_family: 0,
    font_weight: 0,
    font_size: 1,
    heading_text_color: "#ffffff",
    message_text_color: "#ffffff",
    audio_volume: 100,
    image:
      "https://cdn.eplayment.com/fm/06e80d248a14b72b9858104285a595cd/image-1.png",
  });

  const [alertNotifs, setAlertNotifs] = useState<
    {
      alert_heading: "";
      alert_message: "";
    }[]
  >([]);

  const [activeAlert, setActiveAlert] = useState<{
    alert_heading: string;
    alert_message: string;
  } | null>(null);

  const variants = {
    initial: {
      opacity: 0,
      y: 140,
    },
    animate: {
      y: [140, 0],
      opacity: [0, 1],
      transition: {
        delay: 0.3,
        duration: 0.3,
      },
    },
    exit: {
      y: -140,
      opacity: 0,
      transition: {
        delay: alertSettings.alert_duration,
        duration: 0.3,
      },
    },
  };

  const controls = useAnimationControls();

  const [isLoading, setisLoading] = useState(false);
  const [isLoadingAnim, setisLoadingAnim] = useState(false);

  const playAudio = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCtx = new window.AudioContext();
      await audioCtx.resume();

      const w = new Audio();
      w.src = audios[0].src;
      const newVolume = alertSettings.audio_volume * 0.01;
      if (newVolume <= 1) {
        w.volume = newVolume;
        w.play();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const isProd = window?.location.host === process.env.REACT_APP_DOMAIN;

    const config = isProd
      ? {
          key: process.env.REACT_APP_PUSHER_KEY as string,
          cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
          wsHost: process.env.REACT_APP_PUSHER_WS_HOST as string,
          wsPort: parseFloat(process.env.REACT_APP_PUSHER_WS_PORT ?? "443"),
        }
      : {
          key: process.env.REACT_APP_DEV_PUSHER_KEY as string,
          cluster: process.env.REACT_APP_DEV_PUSHER_CLUSTER as string,
          wsHost: process.env.REACT_APP_DEV_PUSHER_WS_HOST as string,
          wsPort: parseFloat(process.env.REACT_APP_DEV_PUSHER_WS_PORT ?? "443"),
        };

    const pusher = new Pusher(config.key, {
      cluster: config.cluster,
      wsHost: config.wsHost,
      wsPort: config.wsPort,
      forceTLS: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
    });

    const channel: any = pusher.subscribe(code?.toString() ?? "");
    channel.bind("eplayment-gift-alert", (data: any) => {
      setAlertSettings({ ...alertSettings, ...data?.alert_settings });
      setAlertNotifs((prev) => [
        ...prev,
        {
          alert_heading: data?.alert?.heading,
          alert_message: data?.alert?.message,
        },
      ]);
    });

    return () => {
      channel.unbind();
      pusher.unsubscribe(channel);
    };
  }, [code]);

  useEffect(() => {
    const handleAnimate = async () => {
      setisLoadingAnim(true);
      setActiveAlert(alertNotifs[0]);
      playAudio();
      await controls.start("animate");
      await controls.start("exit");
      setAlertNotifs((prevArray) => prevArray?.slice(1));
      setisLoadingAnim(false);
    };
    if (isLoadingAnim !== true && alertNotifs.length > 0) {
      handleAnimate();
    }
  }, [alertNotifs]);

  // useEffect(() => {
  //   const handleGetalertSettings = async () => {
  //     setisLoading(true);
  //     try {
  //       const { data } = await axios({
  //         method: "get",
  //         url: "/v2/user/stream/alert/settings",
  //         baseURL:
  //           process.env.REACT_APP_DOMAIN === window?.location.host
  //             ? process.env.REACT_APP_PROD_API_HOST
  //             : process.env.REACT_APP_DEV_API_HOST,
  //         headers: {
  //           Authorization: `Bearer ${code}`,
  //         },
  //       });
  //       setAlertSettings({ ...alertSettings, ...data.data });
  //       setisLoading(false);
  //     } catch (err) {
  //       setisLoading(false);
  //     }
  //   };
  //   handleGetalertSettings();
  // }, []);
  return (
    <div>
      <motion.div
        animate={controls}
        initial={!activeAlert && "initial"}
        variants={variants}
      >
        {!isLoading ? (
          <Sticker
            alert_settings={alertSettings}
            alert_heading={activeAlert?.alert_heading ?? alert_heading}
            alert_message={activeAlert?.alert_message ?? alert_message}
          />
        ) : null}
      </motion.div>
    </div>
  );
};

export default ContentStreamCode;
